<template>
  <div>
    <h1 class="title">Artist: {{ user.name }}</h1>
      <div class="content">
        <h2>Biographie of {{ user.name }}</h2>
        <p>{{ user.bio }}</p>
        <h2>Public Address</h2>
        <input type="text" :value="user.publicAddress" disabled>
      </div>

    <br>
    <br>
    <h1>NFTs from this Collection on Sale</h1> <br>
    <div class="content">
      <div class="container" v-if="listings.length>0">
        <div :key="listing.id" v-for="listing in listings" class="nft_listing">
          <ListingOverview v-if="listing" :inputListing="listing"
                           :key="listing.id"/>
        </div>
      </div>
      <div v-else class="flexCenterCenter">
        <img class="illustration" src="@/assets/no_data.svg" alt="">
        <h2>No NFT on Sale in this collection (based on our Records)</h2>
      </div>
    </div>

    <br>
    <br>

    <h1>Other NFT in this Collection</h1><br>
    <div class="content">
      <div class="container" v-if="nfts.length>0">
        <div :key="nft.id" v-for="nft in nfts" class="nft_listing">
          <nft-overview v-if="nft" :inputNft="nft" :key="nft.tokenId"></nft-overview>
        </div>
      </div>
      <div v-else class="flexCenterCenter">
        <img class="illustration" src="@/assets/no_data.svg" alt="">
        <h2>No NFT in this Collection <br>(Based on our Records)</h2>
      </div>
    </div>

  </div>

</template>

<script>
import NftOverview from "./nftOverview";
import axios from "axios";
import Api from "../../services/Api";
import ListingOverview from "./listingOverview";

export default {
  name: "moreNftFromCollection",
  components: {ListingOverview, NftOverview},
  props: {
    collectionID: Number,
  },
  data() {
    return {
      collection: null,
      nfts: null,
      listings: null,
      user: null,
    }
  },
  mounted() {
    this.load()
  },
  activated() {
    this.load()
  },
  methods: {
    async load() {
      await this.getCollection(this.collectionID)
    },
    async getCollection(id) {
      const response = await axios.get(Api.baseUrl + "/collections/" + id)
      this.collection = response.data[0]

      const responseNFT = await axios.get(Api.baseUrl + '/nftsByCollection/' + this.collection.id)
      this.nfts = responseNFT.data

      const responseListings = await axios.get(Api.baseUrl + '/listingsByCollection/' + this.collection.id)

      let listingsModel = responseListings.data

      for (let listing of listingsModel) {
        if (listing.nft_id) {
          const response2 = await axios.get(Api.baseUrl + "/nfts/" + listing.nft_id)
          listing.nft = response2.data[0]
          // console.log(response2)
        }
      }


      this.listings = listingsModel


      // console.log(response2)
      // this.nft.ipfs = await IpfsAPI.getNFTMeta(this.nft.nftURI)
      //
      await this.getUser(this.collection.user_id);
    },

    async getUser(id) {
      const response = await axios.get(Api.baseUrl + "/users/" + id)
      this.user = response.data[0]
    },

  }
}
</script>

<style scoped>

</style>