<template>
  <div>
    <!--    hallo-->
    <div class="displayNone">
      <!--      Braucht es da sonst ein bug bezüglich der anzeige entsteht wenn sie nicht zufor aufgerufen werden-->
      {{ nft }}
      {{ nft.ipfs }}
      {{ user }}
    </div>
    <div class="home" v-if="nft && nft.ipfs && user && !loading">
      <div class="content nftMedia">
        <nft-display :mediaURL="nft.ipfs.image"/>
      </div>
      <br>
      <h1 class="title">{{ nft.ipfs.name }}</h1>
      <div class="content">
        <!--        <div class="col2">-->
        <!--          <div class="card">-->
        <h2>NFT Description</h2>
        <router-link :to="'/users/' + user.id"><b>Owner: {{ user.name }}</b></router-link>
        <p>{{ nft.ipfs.description }}</p>
        <!--          </div>-->
        <!--          <div class="card">-->
        <!--            <collection-info-box :collection="nft.collection" :nft="nft"/>-->
        <!--          </div>-->
        <!--        </div>-->

      </div>

      <br>
      <br>


      <more-nft-from-collection :collectionID="nft.collection_id"></more-nft-from-collection>
      <br>
      <br>

      <br>
      <br> <br>
      <br> <br>
      <br>

      <!--    <img alt="Vue logo" src="../assets/logo.png">-->
      <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    </div>
    <Loader v-if="loading"/>
  </div>
</template>

<script>
import axios from "axios";
// import NftOverview from "../components/nft/nftOverview";
import Api from "../../services/Api";
import IpfsAPI from "../../services/IpfsAPI";
import UtilityService from "../../services/UtilityService";
import NftDisplay from "../../components/nft/nftDisplay";
import MoreNftFromCollection from "../../components/nft/moreNftFromCollection";
import Loader from "../../components/ui-components/loader";
// import CollectionInfoBox from "../../components/nft/collectionInfoBox";
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'nftSingle',
  components: {
    Loader,
    // CollectionInfoBox,
    MoreNftFromCollection,
    NftDisplay
    // NftOverview
    // HelloWorld
  },
  data() {
    return {
      UtilityService: UtilityService,
      loading: false,
      nft_id: null,
      nft: {},
      user: {}
    }
  },
  mounted() {
    this.init()
  },

  watch: {
    $route(to, from) {
      console.log(to, from)
      this.$nextTick(() => {
        this.init()
      })
    }
  },

  beforeRouteLeave(name, from, next) {
    this.nft = {}
    this.user = {}
    next();
  },

  methods: {
    init() {
      this.$nextTick(() => {
        this.nft_id = this.$route.params.id
        this.getListing(this.nft_id);
      })
    },
    async getListing(id) {
      this.loading = true

      const response = await axios.get(Api.baseUrl + "/nfts/" + id)
      this.nft = response.data[0]
      // console.log(response2)

      try {
        this.nft.ipfs = await IpfsAPI.getNFTMeta(this.nft.nftURI)
        this.loading = false
      } catch (e) {
        console.error(e)
        this.loading = false
        alert('Something unexpected happened. Please try again')
      }
      await this.getUser(this.nft.user_id);
    },

    async getUser(id) {
      const response = await axios.get(Api.baseUrl + "/users/" + id)
      this.user = response.data[0]
    }
  },
}
</script>
 <style scoped lang="scss">
@import 'src/styles/style.scss';


.displayNone {
  display: none;
}

.nftMedia {

  background: transparent;
  padding: 0;
  margin-bottom: 16px;

  img, video {
    width: 100%;
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px 18px;
  grid-auto-flow: row;
}



.nft_listing {
  //max-width: 100%;

  img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
  }
}

.nft_upper_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
